<template>
  <div>

    <!-- <form @submit.prevent="checkInDate()">

        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label>date de debut </label>
                    <input v-model="form.debut" class="form-control" required type="datetime-local">
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label>date de fin </label>
                    <input v-model="form.fin" class="form-control" required type="datetime-local">

                </div>
            </div>

            <div class="col-sm-2">
                <div class="form-group">
                    </br>
                    <button class="btn btn-primary" type="submit">Valider</button>

                </div>
            </div>


        </div>
    </form> -->

    <div :key="key" class="row">
      <b-modal :id="formId" :size="formWidth">
        <template #modal-title>
          <div v-if="formState == 'Update'">Update Journals #{{ formData.id }}</div>
          <div v-if="formState == 'Create'">Create Journals</div>
          <div v-if="formState == 'CreateRapport'">Generer un rapport</div>
          <div v-if="formState == 'DetailTaches'">Listes des taches associer au pointages en fonction de la
            pointeuse
          </div>
          <div v-if="formState == 'DetailPostes'">Listes des postes associer au pointages en fonction de la
            pointeuse
          </div>
        </template>


        <EditJournals v-if="formState == 'Update'" :key="formKey" :actifsData="actifsData"
                      :balisesData="balisesData" :categoriesData="categoriesData" :contratsData="contratsData"
                      :data="formData" :directionsData="directionsData" :echelonsData="echelonsData"
                      :factionsData="factionsData" :fonctionsData="fonctionsData" :gridApi="formGridApi"
                      :matrimonialesData="matrimonialesData" :modalFormId="formId" :nationalitesData="nationalitesData"
                      :onlinesData="onlinesData" :pointeusesData="pointeusesData" :postesData="postesData"
                      :sexesData="sexesData" :sitesData="sitesData" :situationsData="situationsData"
                      :typesData="typesData" :villesData="villesData" :zonesData="zonesData" @close="closeForm"/>

        <TachesView v-if="formState == 'DetailTaches'" :data="formData">

        </TachesView>
        <PostesView v-if="formState == 'DetailPostes'" :data="formData">

        </PostesView>
        <CreateJournals v-if="formState == 'Create'" :key="formKey" :actifsData="actifsData"
                        :balisesData="balisesData" :categoriesData="categoriesData" :contratsData="contratsData"
                        :directionsData="directionsData" :echelonsData="echelonsData" :factionsData="factionsData"
                        :fonctionsData="fonctionsData" :gridApi="formGridApi" :matrimonialesData="matrimonialesData"
                        :modalFormId="formId" :nationalitesData="nationalitesData" :onlinesData="onlinesData"
                        :pointeusesData="pointeusesData" :postesData="postesData" :sexesData="sexesData"
                        :sitesData="sitesData" :situationsData="situationsData" :typesData="typesData"
                        :villesData="villesData" :zonesData="zonesData" @close="closeForm"/>

        <GetRapport v-if="formState == 'CreateRapport'" :key="formKey" :actifsData="actifsData"
                    :balisesData="balisesData" :categoriesData="categoriesData" :contratsData="contratsData"
                    :directionsData="directionsData" :echelonsData="echelonsData" :factionsData="factionsData"
                    :fonctionsData="fonctionsData" :gridApi="formGridApi" :matrimonialesData="matrimonialesData"
                    :modalFormId="formId" :nationalitesData="nationalitesData" :onlinesData="onlinesData"
                    :pointeusesData="pointeusesData" :postesData="postesData" :sexesData="sexesData"
                    :sitesData="sitesData" :situationsData="situationsData" :typesData="typesData"
                    :villesData="villesData" :zonesData="zonesData" @close="closeForm"/>

        <template #modal-footer>
          <div>

          </div>
        </template>
      </b-modal>


      <div class="col-sm-12">
        <AgGridTable :key="tableKey" :cacheBlockSize="cacheBlockSize" :columnDefs="columnDefs"
        :defaultColumnsOrder="['punch_time','users.matricule','users.nom','users.prenom','card_no','pointeuses.libelle','pointeusessites.libelle','zones.libelle','postessites.libelle','clients.libelle']"
                     :extrasData="extrasData" :maxBlocksInCache="maxBlocksInCache" :pagination="pagination"
                     :paginationPageSize="paginationPageSize" :rowData="rowData" :rowModelType="rowModelType" :url="url"
                     className="ag-theme-alpine" domLayout='autoHeight' rowSelection="multiple"
                     @gridReady="onGridReady">
          <template #header_buttons>
            <div v-if="!$route.meta.hideCreate" class="btn btn-primary" @click="openCreate"><i
                class="fa fa-plus"></i>
              Nouveau
            </div>
            <div class=" form-group mx-2">
                            <label class="col-form-label-sm">date de debut </label>
                            <input v-model="form.debut" class="form-control  form-control-sm" required
                                   type="datetime-local">
                        </div>
                        <div class="form-group ">
                            <label class="col-form-label-sm">date de fin </label>
                            <input v-model="form.fin" class="form-control form-control-sm" required
                                   type="datetime-local">

            </div>
          </template>

        </AgGridTable>

      </div>
    </div>
  </div>
</template>


<script>
import {mapGetters} from 'vuex';



export default {
  name: 'JournalsView',
  components: {
    DataTable: () => import( "@/components/DataTable.vue"),
    AgGridTable: () => import("@/components/AgGridTable.vue"),
    DataModal: () => import("@/components/DataModal.vue"),
    AgGridBtnClicked: () => import("@/components/AgGridBtnClicked.vue"),
    CustomFiltre: () => import("@/components/CustomFiltre.vue"),
    CreateJournals: () => import("./CreateJournals.vue"),
    EditJournals: () => import("./EditJournals.vue"),
    GetRapport: () => import("./GetRapport.vue"),
    TachesView: () => import("./TachesView.vue"),
    PostesView: () => import("./PostesView.vue"),
  },
  data() {

    return {
      showFormElement: false,
      formId: "journals",
      formState: "",
      formData: {},
      formWidth: 'lg',
      formGridApi: {},
      formKey: 0,
      tableKey: 0,
      url: 'http://127.0.0.1:8000/api/journals-Aggrid1',
      table: 'journals',
      actifsData: [],
      balisesData: [],
      directionsget: [],
      categoriesData: [],
      contratsData: [],
      directionsData: [],
      echelonsData: [],
      factionsData: [],
      directionselectionner: [],
      fonctionsData: [],
      matrimonialesData: [],
      nationalitesData: [],
      onlinesData: [],
      pointeusesData: [],
      postesData: [],
      tachesData: [],
      sexesData: [],
      sitesData: [],
      clientsData: [],
      situationsData: [],
      typesData: [],
      villesData: [],
      zonesData: [],
      requette: 19,
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      rowModelType: null,
      pagination: true,
      paginationPageSize: 25,
      cacheBlockSize: 10,
      maxBlocksInCache: 1,
      key: 0,
      form: {
        debut: 0,
        fin: 0
      },
    }
  },

  computed: {
    ...mapGetters('app', ['subDomain', 'domain']),
    $routeData: function () {
      let router = {meta: {}};
      try {
        if (typeof window.routeData != 'undefined') {
          router = window.routeData
        }
      } catch (e) {
      }

      return router;
    },
    params: function () {
      let params = {};
      try {
        if (typeof window.params != 'undefined') {
          params = window.params
        }
      } catch (e) {
      }
      return params;
    },
    taille: function () {
      let result = 'col-sm-12'
      if (this.filtre) {
        result = 'col-sm-9'
      }
      return result
    },
    extrasData: function () {

      let params = {};
      if (this.form.debut && this.form.fin) {
        let datesDebut = this.form.debut.toLowerCase().split('t');
        let newDateDebut = datesDebut[0] + " " + datesDebut[1]
        let datesFin = this.form.fin.toLowerCase().split('t');
        let newDateFin = datesFin[0] + " " + datesFin[1]

        params['transactions.punch_time'] = {values: [newDateDebut, newDateFin], filterType: "between"};

      } else if (this.form.debut && !this.form.fin) {
        let datesDebut = this.form.debut.toLowerCase().split('t');
        let newDateDebut = datesDebut[0] + " " + datesDebut[1]

        params['transactions.punch_date'] = {value: newDateDebut, filterType: "superieur"};
      } else if (this.form.fin && !this.form.debut) {
        let datesFin = this.form.fin.toLowerCase().split('t');
        let newDateFin = datesFin[0] + " " + datesFin[1]
        params['transactions.punch_time'] = {value: newDateFin, filterType: "inferieur"};
      }
      this.tableKey++;

      return {
        baseFilter: params,

      }


    },
  },
  watch: {
    '$route': {
      handler: function (after, before) {
        this.gridApi.setFilterModel(null)
        this.gridApi.refreshServerSide()
        this.tableKey++
      },
      deep: true
    },
  },
  created() {
    this.url = this.axios.defaults.baseURL + '/api/transactions-Aggrid1',
        this.formId = this.table + "_" + Date.now()
    this.rowBuffer = 0;
    this.rowModelType = 'serverSide';
    this.cacheBlockSize = 50;
    this.maxBlocksInCache = 2;

  },
  beforeMount() {

    this.columnDefs =
    [
                {
                    field: "transactions.id",
                    sortable: true,

                    filter: 'agTextColumnFilter',
                    floatingFilter: true,
                    filterParams: {suppressAndOrCondition: true,},
                    hide: true,
                    headerName: '#Id',
                },
                {
                    field: null,
                    headerName: '',
                    suppressCellSelection: true,
                    width: 80,
                    pinned: 'left',
                    cellRendererSelector: params => {
                        return {
                            component: 'AgGridBtnClicked',
                            params: {
                                clicked: field => {
                                    this.showForm('Update', field, params.api)
                                },
                                render: `<div class="" style="width:100%;height:100%;background:#28a745;color:#fff;border-radius:5px;text-align:center;cursor:pointer">  <i class="fa-solid fa-pen-to-square "></i></div>`
                            }
                        };
                    },

                },
                {
                    field: "punch_time",
                    width: 100,
                    hide: true,
                    suppressColumnsToolPanel: true,
                    filter: 'agTextColumnFilter',
                    floatingFilter: true, filterParams: {suppressAndOrCondition: true,},
                    headerName: '',
                    valueFormatter: params => {
                        let retour = params.value
                        try {
                            retour = params.value.split(' ')[0]
                        } catch (e) {

                        }
                        return retour
                    }
                },
                {
                    field: "",
                                        width: 100,
                    valueGetter: this.fullNameGetter,
                    filter: 'agTextColumnFilter',
                    floatingFilter: true, filterParams: {suppressAndOrCondition: true,},
                    sortable: true,
                    headerName: 'jour',
                                        pinned: 'left',
                },
                {
                    field: "",
                                        width: 100,
                    valueGetter: this.fullNameGetter2,
                    sortable: true,
                    filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
                    headerName: 'heure',
                                        pinned: 'left',
                },
                {
                    field: "users.matricule",
                    sortable: true,
                    width: 130,
                    filter: 'agTextColumnFilter',
                    floatingFilter: true, filterParams: {suppressAndOrCondition: true,},
                    headerName: 'Numero Matricule',
                    join: {
                        table: 'users',
                        champ1: 'transactions.card_no',
                        champ2: 'users.num_badge',
                        operateur: '=',
                    }
                },
                {
                  field: "users.nom",
                  sortable: true,
                  width: 180,
                  filter: 'agTextColumnFilter',
                  floatingFilter: true, filterParams: {suppressAndOrCondition: true,},
                  headerName: 'Nom',
                },
                {
                  field: "users.prenom",
                  sortable: true,
                  width: 180,
                  filter: 'agTextColumnFilter',
                  floatingFilter: true, filterParams: {suppressAndOrCondition: true,},
                  headerName: 'Prenom',
                },
                {
                  field: "card_no",
                  sortable: true,
                  width: 100,
                  filter: 'agTextColumnFilter',
                  floatingFilter: true, filterParams: {suppressAndOrCondition: true,},
                  headerName: 'UUID MIFARE',
                },
                {

                  headerName: 'terminal',
                  field: 'pointeuses.libelle',
                  width: 200,
                  filter: 'agTextColumnFilter',
                  floatingFilter: true, filterParams: {suppressAndOrCondition: true,},
                  suppressColumnsToolPanel: true,
                  join: {
                    table: 'pointeuses',
                    tableAlias: 'pointeuses',
                    champ1: 'transactions.terminal_alias',
                    champ2: 'pointeuses.code',
                    operateur: '=',
                  }

                }

            ];


  },
  mounted() {
    if (this.requette > 0) {
      // this.$store.commit('setIsLoading', true)
    }
    // let params = {};
    // try {
    //     if (typeof window.params != 'undefined') {
    //         params = window.params
    //     }
    // } catch (e) {
    // }
    // if ('debut' in params) {
    //     this.form.debut = params.debut
    // }
    // if ('fin' in params) {
    //     this.form.fin = params.fin
    // }
    this.form.debut = new Date().toISOString().slice(0, 11) + '00:00',
        this.form.fin = new Date().toISOString().slice(0, 11) + '23:59'

    console.log('voila les params', params)
    this.directionsget = this.$route.meta.directionsGet

    // this.getactifs();
    // this.getbalises();
    // this.getcategories();
    // this.getcontrats();
    // this.getdirections();
    // this.getechelons();
    // this.getfactions();
    // this.getfonctions();
    // this.getmatrimoniales();
    // this.getnationalites();
    // this.getonlines();
    // this.getpointeuses();
    // this.getpostes();
    // this.gettaches();
    // this.getsexes();
    // this.getsites();
    // this.getclients();
    // this.getfonctions();
    // this.getsituations();
    // this.gettypes();
    // this.getvilles();
    // this.getzones();
    // this.gettransactions();

  },
  methods: {
    fullNameGetter(params) {
            let data;
            if ((params.data.punch_time)) {
data = (params.data.punch_time).split(" ")[0];
                return data;

            }  else {

                return 'non defini';
            }
        },
        fullNameGetter2(params) {
            let data;
            if ((params.data.punch_time)) {
data = (params.data.punch_time).split(" ")[1];
                return data;

            }  else {

                return 'non defini';
            }
        },
    openCreate() {
      this.showForm('Create', {}, this.gridApi)
    },
    openCreateRapport() {
      this.showForm('CreateRapport', {}, this.gridApi, 'xl')
    },
    checkInDate() {
      // alert('on as chosit entre les dates et on peut desormait affricher le formulaire')
      this.key++
    },
    closeForm() {
      try {
        this.gridApi.refreshServerSide()
      } catch (e) {

      }
    },
    showForm(type, data, gridApi, width = 'lg') {
      this.formKey++
      this.formWidth = width
      this.formState = type
      this.formData = data
      this.formGridApi = gridApi
      this.$bvModal.show(this.formId)
    },
    onGridReady(params) {
      console.log('on demarre', params)
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.isLoading = false
    },
    getactifs() {
      this.axios.get('/api/actifs').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // // this.$store.commit('setIsLoading', false)
        }
        this.actifsData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },

    getbalises() {
      this.axios.get('/api/balises').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // // this.$store.commit('setIsLoading', false)
        }
        this.balisesData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },
    getclients() {
      this.axios.get('/api/clients').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // // this.$store.commit('setIsLoading', false)
        }
        this.clientsData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },


    getcategories() {
      this.axios.get('/api/categories').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // // this.$store.commit('setIsLoading', false)
        }
        this.categoriesData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },

    getcontrats() {
      this.axios.get('/api/contrats').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // // this.$store.commit('setIsLoading', false)
        }
        this.contratsData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },

    getdirections() {
      this.axios.get('/api/directions').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // // this.$store.commit('setIsLoading', false)
        }
        this.directionsData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },

    getechelons() {
      this.axios.get('/api/echelons').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // // this.$store.commit('setIsLoading', false)
        }
        this.echelonsData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },

    getfactions() {
      this.axios.get('/api/factions').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // // this.$store.commit('setIsLoading', false)
        }
        this.factionsData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },

    getfonctions() {
      this.axios.get('/api/fonctions').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // // this.$store.commit('setIsLoading', false)
        }
        this.fonctionsData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },

    getmatrimoniales() {
      this.axios.get('/api/matrimoniales').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // // this.$store.commit('setIsLoading', false)
        }
        this.matrimonialesData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },

    getnationalites() {
      this.axios.get('/api/nationalites').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // this.$store.commit('setIsLoading', false)
        }
        this.nationalitesData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },

    getonlines() {
      this.axios.get('/api/onlines').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // this.$store.commit('setIsLoading', false)
        }
        this.onlinesData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },

    getpointeuses() {
      this.axios.get('/api/pointeuses').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // this.$store.commit('setIsLoading', false)
        }
        this.pointeusesData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },

    getpostes() {
      this.axios.get('/api/postes').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // this.$store.commit('setIsLoading', false)
        }
        this.postesData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },

    gettaches() {
      this.axios.get('/api/taches').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // this.$store.commit('setIsLoading', false)
        }
        this.tachesData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },

    getsexes() {
      this.axios.get('/api/sexes').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // this.$store.commit('setIsLoading', false)
        }
        this.sexesData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },

    getsites() {
      this.axios.get('/api/sites').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // this.$store.commit('setIsLoading', false)
        }
        this.sitesData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },

    getsituations() {
      this.axios.get('/api/situations').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // this.$store.commit('setIsLoading', false)
        }
        this.situationsData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },

    gettypes() {
      this.axios.get('/api/types').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // this.$store.commit('setIsLoading', false)
        }
        this.typesData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },

    getvilles() {
      this.axios.get('/api/villes').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // this.$store.commit('setIsLoading', false)
        }
        this.villesData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },

    getzones() {
      this.axios.get('/api/zones').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // this.$store.commit('setIsLoading', false)
        }
        this.zonesData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },
    directionsselect(direction) {

      if (this.directionselectionner.includes(direction)) {
        const index = this.directionselectionner.indexOf(direction);
        if (index !== -1) {
          this.directionselectionner.splice(index, 1);
        }
      } else {
        this.directionselectionner.push(direction);
      }

      this.extrasData1.directionselectionner = this.directionselectionner

    },

    // gettransactions() {
    //
    //     this.axios.get('/api/transactions').then((response) => {
    //         this.requette--
    //         if (this.requette == 0) {
    //             // this.$store.commit('setIsLoading', false)
    //         }
    //         // this.transactionsData = response.data
    //         console.log('users', response.data)
    //
    //     }).catch(error => {
    //         console.log(error.response.data)
    //         // this.$store.commit('setIsLoading', false)
    //         this.$toast.error('Erreur survenue lors de la récuperation')
    //     })
    // },


  }
}
</script>
<style>
.haut,
.bas {
    display: flex
}

.allBoutons {
    display: flex;
    gap: 10px
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>

